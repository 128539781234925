export const SERVICE_COMPONENTS = ['websocket'];

export const LAYOUT_COMPONENTS = [
  'form-page-shell',
  'list-page-shell',
  'loading',
  'logs',
  'map-container',
  'save-bar',
  'table-container',
  'tabs-page-shell',
  'top-bar',
];

export const CHARTS_COMPONENTS = [
  'bar-chart',
  'donut-chart',
  'line-chart',
  'pie-chart',
];

export const UI_COMPONENTS = [
  'action',
  'address-form-field',
  'address-picker',
  'app-bar',
  'autocomplete',
  'battery-progress',
  'breadcrumb',
  'button-group',
  'card',
  'channel-value',
  'checkbox',
  'checkbox-group',
  'chip',
  'color-picker',
  'cookie-consent',
  'date-range-picker',
  'datepicker',
  'day-picker',
  'dialog',
  'editable-content',
  'empty-state',
  'expansion-panel',
  'fab-card',
  'fab-icon',
  'fab-stepper',
  'file-drop-area',
  'file-picker',
  'form-field',
  'gauge',
  'hero',
  'hierarchy',
  'icon',
  'image',
  'image-gallery',
  'info-box',
  'input',
  'language-picker',
  'lightbox',
  'list-header',
  'list-item',
  'list-item-tag',
  'logo',
  'lottie-animation',
  'map',
  'mobile-stepper',
  'month-picker',
  'multi-language-form-field',
  'multi-language-form-field-group',
  'nav-tab',
  'nested-expansion-panel',
  'notification',
  'notifications-panel',
  'phone-input',
  'pin-code',
  'progress-bar',
  'progress-spinner',
  'radio-group',
  'rating',
  'readonly-form-field',
  'scheduler',
  'score-card',
  'search',
  'select',
  'sidenav',
  'slide-toggle',
  'status-avatar',
  'tab',
  'table',
  'textarea',
  'textarea-form-field',
  'time-line',
  'time-picker',
  'timestamp',
  'toolbar',
  'toolbar-menu',
  'tooltip',
  'tour',
  'typography',
  'user-menu',
  'vertical-time-line',
  'wizard',
  'year-picker',
];

import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { WebsocketService } from '@core/websocket';
import { LanguageCode } from '@model';
import { DialogModule } from '@ui/dialog';
import { LanguageModule, MultiLanguageFallback } from '@ui/language';
import { provideLottieAnimation } from '@ui/lottie-animation';
import { NotificationComponent, NotificationService } from '@ui/notification';
import { provideTimeLineKey } from '@ui/time-line';
import { provideTour } from '@ui/tour';
import { enUS } from 'date-fns/locale';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';

const multiLanguageFallback: MultiLanguageFallback = (
  _languageCode: LanguageCode,
) => {
  return '';
};

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    provideAnimations(),
    provideLottieAnimation(),
    WebsocketService,
    NotificationService,
    provideTour(),
    provideTimeLineKey(environment.schedulerLicenseKey),
    importProvidersFrom(
      DialogModule,
      ToastrModule.forRoot({
        toastComponent: NotificationComponent,
      }),
      RouterModule.forRoot(APP_ROUTES),
      LanguageModule.forRoot(multiLanguageFallback),
    ),
  ],
};

<etn-sidenav
  etnTheme
  [config]="sideNavConfig"
  [opened]="sideNavOpened"
  [variant]="sideNavVariant"
  [brand]="brand"
  [title]="'Testbed'"
  [theme]="theme"
  [favicon]="favicon"
  [activeItemId]="sideNavActiveItemId"
  (itemSelect)="onItemSelect($event)"
  (toggle)="onToggle()"
>
  <div class="router-outlet">
    <router-outlet></router-outlet>
  </div>
</etn-sidenav>

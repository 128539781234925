import { DOCUMENT } from '@angular/common';
import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  RendererFactory2,
} from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

@Directive({
  selector: '[etnTheme]',
})
export class ThemeDirective implements OnChanges {
  public constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.hideBody();
  }

  @Input() public brand: string | Nil;
  @Input() public theme: string | Nil;
  @Input() public favicon: string | Nil;

  @Output() public themeLoading = new EventEmitter<boolean>();
  @Output() public fallback = new EventEmitter<void>();

  private renderer = this.rendererFactory.createRenderer(null, null);
  private head = this.document.head;

  public ngOnChanges(): void {
    if (
      isNotNil(this.brand) &&
      isNotNil(this.theme) &&
      isNotNil(this.favicon)
    ) {
      this.setTheme(this.brand, this.theme, this.favicon);
    }
  }

  private setTheme(brand: string, theme: string, favicon: string): void {
    this.themeLoading.emit(true);
    const themeLinkElement = this.getThemeLinkElement();

    document.body.classList.add(brand);

    if (isNil(themeLinkElement) || !themeLinkElement.href.endsWith(theme)) {
      if (isNotNil(themeLinkElement)) {
        this.renderer.removeChild(this.head, themeLinkElement);
      }
      this.loadCss(theme);
    } else {
      this.showBody();
    }

    const faviconLinkElement = this.getFaviconLinkElement();

    if (
      isNil(faviconLinkElement) ||
      !faviconLinkElement.href.endsWith(favicon)
    ) {
      if (isNotNil(faviconLinkElement)) {
        this.renderer.removeChild(this.head, faviconLinkElement);
      }
      if (isNotNil(favicon)) {
        this.loadFavicon(favicon);
      }
    }
  }

  private loadCss(filename: string): void {
    const linkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
    this.renderer.setAttribute(linkElement, 'id', 'theme-link');
    this.renderer.setAttribute(linkElement, 'type', 'text/css');
    this.renderer.setAttribute(linkElement, 'href', filename);
    this.renderer.setProperty(linkElement, 'onload', () => {
      this.themeLoading.emit(false);
      this.showBody();
    });
    this.renderer.setProperty(linkElement, 'onerror', () => {
      this.setFallbackTheme();
      this.showBody();
    });
    this.renderer.insertBefore(this.head, linkElement, this.head.firstChild);
  }

  private loadFavicon(filename: string): void {
    const faviconElement = this.renderer.createElement('link');
    this.renderer.setAttribute(faviconElement, 'rel', 'icon');
    this.renderer.setAttribute(faviconElement, 'id', 'favicon-link');
    this.renderer.setAttribute(faviconElement, 'type', 'image/png');
    this.renderer.setAttribute(faviconElement, 'href', filename);
    this.renderer.appendChild(this.head, faviconElement);
  }

  private setFallbackTheme(): void {
    this.fallback.emit();
  }

  private hideBody(): void {
    document.body.style.display = 'none';
  }

  private showBody(): void {
    document.body.style.display = 'block';
  }

  private getThemeLinkElement(): HTMLLinkElement | Nil {
    return this.head.querySelector<HTMLLinkElement>('#theme-link');
  }

  private getFaviconLinkElement(): HTMLLinkElement | Nil {
    return this.head.querySelector<HTMLLinkElement>('#favicon-link');
  }
}

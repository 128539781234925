import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: 'vertical-time-line',
    loadComponent: () => {
      return import('./pages/ui/vertical-time-line-page');
    },
  },
  {
    path: 'gauge',
    loadComponent: () => {
      return import('./pages/ui/gauge-page');
    },
  },
  {
    path: 'mobile-stepper',
    loadComponent: () => {
      return import('./pages/ui/mobile-stepper-page');
    },
  },
  {
    path: 'signals',
    loadComponent: () => {
      return import('./pages/documentation/signals-page');
    },
  },
  {
    path: 'i18n',
    loadComponent: () => {
      return import('./pages/i18n/i18n-page');
    },
  },
  {
    path: 'score-card',
    loadComponent: () => {
      return import('./pages/ui/score-card-page');
    },
  },
  {
    path: 'timestamp',
    loadComponent: () => {
      return import('./pages/ui/timestamp-page');
    },
  },
  {
    path: 'status-avatar',
    loadComponent: () => {
      return import('./pages/ui/status-avatar-page');
    },
  },
  {
    path: 'logs',
    loadComponent: () => {
      return import('./pages/layout/logs-page');
    },
  },
  {
    path: 'year-picker',
    loadChildren: async () => {
      return (
        await import('./pages/ui/year-picker-page/year-picker-page.routes')
      ).routes;
    },
  },
  {
    path: 'line-chart',
    loadChildren: async () => {
      return (await import('./pages/charts/line-chart-page'))
        .lineChartPageRoutes;
    },
  },
  {
    path: 'bar-chart',
    loadChildren: async () => {
      return (await import('./pages/charts/bar-chart-page')).barChartPageRoutes;
    },
  },
  {
    path: 'lottie-animation',
    loadComponent: () => {
      return import('./pages/ui/lottie-animation-page');
    },
  },
  {
    path: 'typography',
    loadComponent: () => {
      return import('./pages/ui/typography-page');
    },
  },
  {
    path: 'user-menu',
    loadComponent: () => {
      return import('./pages/ui/user-menu-page');
    },
  },
  {
    path: 'toolbar-menu',
    loadComponent: () => {
      return import('./pages/ui/toolbar-menu-page');
    },
  },
  {
    path: 'sidenav',
    loadComponent: () => {
      return import('./pages/ui/sidenav-page');
    },
  },
  {
    path: 'documentation',
    loadComponent: () => {
      return import('./pages/documentation/documentation-page');
    },
  },
  {
    path: 'websocket',
    loadChildren: async () => {
      return (await import('./pages/services/websocket-page')).websocketRoutes;
    },
  },
  {
    path: 'form-page-shell',
    loadComponent: () => {
      return import('./pages/layout/form-page-shell-page');
    },
  },
  {
    path: 'list-page-shell',
    loadComponent: () => {
      return import('./pages/layout/list-page-shell-page');
    },
  },
  {
    path: 'loading',
    loadComponent: () => {
      return import('./pages/layout/loading-page');
    },
  },
  {
    path: 'map-container',
    loadComponent: () => {
      return import('./pages/layout/map-container-page');
    },
  },
  {
    path: 'save-bar',
    loadComponent: () => {
      return import('./pages/layout/save-bar-page');
    },
  },
  {
    path: 'table-container',
    loadComponent: () => {
      return import('./pages/layout/table-container-page');
    },
  },
  {
    path: 'tabs-page-shell',
    loadComponent: () => {
      return import('./pages/layout/tabs-page-shell-page');
    },
  },
  {
    path: 'top-bar',
    loadComponent: () => {
      return import('./pages/layout/top-bar-page');
    },
  },
  {
    path: 'action',
    loadChildren: async () => {
      return (await import('./pages/ui/action-page/action-page.routes'))
        .actionPageRoutes;
    },
  },
  {
    path: 'address-form-field',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/address-form-field-page/address-form-field-page.routes'
        )
      ).addressFormFieldPageRoutes;
    },
  },
  {
    path: 'address-picker',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/address-picker-page/address-picker-page.routes'
        )
      ).addressPickerPageRoutes;
    },
  },
  {
    path: 'app-bar',
    loadChildren: async () => {
      return (await import('./pages/ui/app-bar-page/app-bar-page.routes'))
        .appBarPageRoutes;
    },
  },
  {
    path: 'autocomplete',
    loadChildren: async () => {
      return (
        await import('./pages/ui/autocomplete-page/autocomplete-page.routes')
      ).autocompletePageRoutes;
    },
  },
  {
    path: 'battery-progress',
    loadChildren: async () => {
      return (await import('./pages/ui/battery-progress-page'))
        .batteryProgressPageRoutes;
    },
  },
  {
    path: 'breadcrumb',
    loadChildren: async () => {
      return (await import('./pages/ui/breadcrumb-page/breadcrumb-page.routes'))
        .breadcrumbPageRoutes;
    },
  },
  {
    path: 'button-group',
    loadChildren: async () => {
      return (
        await import('./pages/ui/button-group-page/button-group-page.routes')
      ).routes;
    },
  },
  {
    path: 'card',
    loadChildren: async () => {
      return (await import('./pages/ui/card-page/card-page.routes')).routes;
    },
  },
  {
    path: 'channel-value',
    loadChildren: async () => {
      return (
        await import('./pages/ui/channel-value-page/channel-value-page.routes')
      ).routes;
    },
  },
  {
    path: 'checkbox',
    loadChildren: async () => {
      return (await import('./pages/ui/checkbox-page/checkbox-page.routes'))
        .routes;
    },
  },
  {
    path: 'checkbox-group',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/checkbox-group-page/checkbox-group-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'chip',
    loadChildren: async () => {
      return (await import('./pages/ui/chip-page/chip-page.routes')).routes;
    },
  },
  {
    path: 'color-picker',
    loadChildren: async () => {
      return (
        await import('./pages/ui/color-picker-page/color-picker-page.routes')
      ).routes;
    },
  },
  {
    path: 'cookie-consent',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/cookie-consent-page/cookie-consent-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'datepicker',
    loadChildren: async () => {
      return (await import('./pages/ui/datepicker-page/datepicker-page.routes'))
        .routes;
    },
  },
  {
    path: 'date-range-picker',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/date-range-picker-page/date-range-picker-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'day-picker',
    loadChildren: async () => {
      return (await import('./pages/ui/day-picker-page/day-picker-page.routes'))
        .routes;
    },
  },
  {
    path: 'dialog',
    loadChildren: async () => {
      return (await import('./pages/ui/dialog-page/dialog-page.routes')).routes;
    },
  },
  {
    path: 'donut-chart',
    loadComponent: () => {
      return import('./pages/charts/donut-chart-page');
    },
  },
  {
    path: 'editable-content',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/editable-content-page/editable-content-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'empty-state',
    loadChildren: async () => {
      return (
        await import('./pages/ui/empty-state-page/empty-state-page.routes')
      ).routes;
    },
  },
  {
    path: 'expansion-panel',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/expansion-panel-page/expansion-panel-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'nested-expansion-panel',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/nested-expansion-panel-page/nested-expansion-panel-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'fab-card',
    loadChildren: async () => {
      return (await import('./pages/ui/fab-card-page/fab-card-page.routes'))
        .routes;
    },
  },
  {
    path: 'fab-icon',
    loadChildren: async () => {
      return (await import('./pages/ui/fab-icon-page/fab-icon-page.routes'))
        .routes;
    },
  },
  {
    path: 'fab-stepper',
    loadChildren: async () => {
      return (
        await import('./pages/ui/fab-stepper-page/fab-stepper-page.routes')
      ).routes;
    },
  },
  {
    path: 'file-drop-area',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/file-drop-area-page/file-drop-area-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'file-picker',
    loadChildren: async () => {
      return (
        await import('./pages/ui/file-picker-page/file-picker-page.routes')
      ).routes;
    },
  },
  {
    path: 'form-field',
    loadChildren: async () => {
      return (await import('./pages/ui/form-field-page/form-field-page.routes'))
        .routes;
    },
  },
  {
    path: 'hierarchy',
    loadChildren: async () => {
      return (await import('./pages/ui/hierarchy-page/hierarchy-page.routes'))
        .routes;
    },
  },
  {
    path: 'hero',
    loadChildren: async () => {
      return (await import('./pages/ui/hero-page/hero-page.routes')).routes;
    },
  },
  {
    path: 'icon',
    loadChildren: async () => {
      return (await import('./pages/ui/icon-page/icon-page.routes')).routes;
    },
  },
  {
    path: 'image',
    loadChildren: async () => {
      return (await import('./pages/ui/image-page/image-page.routes')).routes;
    },
  },
  {
    path: 'image-gallery',
    loadChildren: async () => {
      return (
        await import('./pages/ui/image-gallery-page/image-gallery-page.routes')
      ).routes;
    },
  },
  {
    path: 'info-box',
    loadChildren: async () => {
      return (await import('./pages/ui/info-box-page/info-box-page.routes'))
        .routes;
    },
  },
  {
    path: 'input',
    loadChildren: async () => {
      return (await import('./pages/ui/input-page/input-page.routes')).routes;
    },
  },
  {
    path: 'language-picker',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/language-picker-page/language-picker-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'lightbox',
    loadChildren: async () => {
      return (await import('./pages/ui/lightbox-page/lightbox-page.routes'))
        .routes;
    },
  },
  {
    path: 'list-header',
    loadChildren: async () => {
      return (
        await import('./pages/ui/list-header-page/list-header-page.routes')
      ).routes;
    },
  },
  {
    path: 'list-item',
    loadChildren: async () => {
      return (await import('./pages/ui/list-item-page/list-item-page.routes'))
        .routes;
    },
  },
  {
    path: 'list-item-tag',
    loadChildren: async () => {
      return (
        await import('./pages/ui/list-item-tag-page/list-item-tag-page.routes')
      ).routes;
    },
  },
  {
    path: 'logo',
    loadChildren: async () => {
      return (await import('./pages/ui/logo-page/logo-page.routes')).routes;
    },
  },
  {
    path: 'map',
    loadChildren: async () => {
      const m = await import('./pages/ui/map-page/map-page.routes');
      return m.routes;
    },
  },
  {
    path: 'month-picker',
    loadChildren: async () => {
      return (
        await import('./pages/ui/month-picker-page/month-picker-page.routes')
      ).routes;
    },
  },
  {
    path: 'multi-language-form-field',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/multi-language-form-field-page/multi-language-form-field-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'multi-language-form-field-group',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/multi-language-form-field-group-page/multi-language-form-field-group-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'nav-tab',
    loadChildren: async () => {
      return (await import('./pages/ui/nav-tab-page/nav-tab-page.routes'))
        .routes;
    },
  },
  {
    path: 'notification',
    loadChildren: async () => {
      return (
        await import('./pages/ui/notification-page/notification-page.routes')
      ).routes;
    },
  },
  {
    path: 'notifications-panel',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/notifications-panel-page/notifications-panel-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'phone-input',
    loadChildren: async () => {
      return (
        await import('./pages/ui/phone-input-page/phone-input-page.routes')
      ).routes;
    },
  },
  {
    path: 'pie-chart',
    loadComponent: () => {
      return import('./pages/charts/pie-chart-page');
    },
  },
  {
    path: 'pin-code',
    loadChildren: async () => {
      return (await import('./pages/ui/pin-code-page/pin-code-page.routes'))
        .routes;
    },
  },
  {
    path: 'progress-bar',
    loadChildren: async () => {
      return (
        await import('./pages/ui/progress-bar-page/progress-bar-page.routes')
      ).routes;
    },
  },
  {
    path: 'progress-spinner',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/progress-spinner-page/progress-spinner-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'radio-group',
    loadChildren: async () => {
      return (
        await import('./pages/ui/radio-group-page/radio-group-page.routes')
      ).routes;
    },
  },
  {
    path: 'rating',
    loadChildren: async () => {
      return (await import('./pages/ui/rating-page/rating-page.routes')).routes;
    },
  },
  {
    path: 'readonly-form-field',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/readonly-form-field-page/readonly-form-field-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'scheduler',
    loadChildren: async () => {
      return (await import('./pages/ui/scheduler-page/scheduler-page.routes'))
        .routes;
    },
  },
  {
    path: 'search',
    loadChildren: async () => {
      return (await import('./pages/ui/search-page/search-page.routes')).routes;
    },
  },
  {
    path: 'select',
    loadChildren: async () => {
      return (await import('./pages/ui/select-page/select-page.routes')).routes;
    },
  },
  {
    path: 'slide-toggle',
    loadChildren: async () => {
      return (
        await import('./pages/ui/slide-toggle-page/slide-toggle-page.routes')
      ).routes;
    },
  },
  {
    path: 'tab',
    loadChildren: async () => {
      return (await import('./pages/ui/tab-page/tab-page.module')).routes;
    },
  },
  {
    path: 'table',
    loadChildren: async () => {
      return (await import('./pages/ui/table-page/table-page.routes')).routes;
    },
  },
  {
    path: 'textarea',
    loadChildren: async () => {
      return (await import('./pages/ui/textarea-page/textarea-page.routes'))
        .routes;
    },
  },
  {
    path: 'textarea-form-field',
    loadChildren: async () => {
      return (
        await import(
          './pages/ui/textarea-form-field-page/textarea-form-field-page.routes'
        )
      ).routes;
    },
  },
  {
    path: 'time-line',
    loadChildren: async () => {
      return (await import('./pages/ui/time-line-page/time-line-page.routes'))
        .routes;
    },
  },
  {
    path: 'time-picker',
    loadChildren: async () => {
      return (
        await import('./pages/ui/time-picker-page/time-picker-page.routes')
      ).routes;
    },
  },
  {
    path: 'toolbar',
    loadChildren: async () => {
      return (await import('./pages/ui/toolbar-page/toolbar-page.routes'))
        .routes;
    },
  },
  {
    path: 'tooltip',
    loadChildren: async () => {
      return (await import('./pages/ui/tooltip-page/tooltip-page.routes'))
        .routes;
    },
  },
  {
    path: 'tour',
    loadChildren: async () => {
      return (await import('./pages/ui/tour-page/tour-page.routes')).routes;
    },
  },
  {
    path: 'wizard',
    loadChildren: async () => {
      return (await import('./pages/ui/wizard-page/wizard-page.module')).routes;
    },
  },
  {
    path: 'wizard',
    loadChildren: async () => {
      return (await import('./pages/ui/wizard-page/wizard-page.module')).routes;
    },
  },
  {
    path: '**',
    redirectTo: 'documentation',
  },
];
